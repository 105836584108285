import React from 'react';
import { IWTwitterFollowProps } from '../WTwitterFollow.types';
import { WTwitterContent } from '../../WTwitterContent/viewer/WTwitterContent';
import { WidgetType } from '../../WTwitterContent/constants';
import { toQueryString } from '../../../core/commons/urlUtils';
import { COMPONENT_LABEL, TITLE_NAME, TWITTER_URL_PREFIX } from './constants';

const WTwitterFollow: React.FC<IWTwitterFollowProps> = props => {
  const { id, translate, onMouseEnter, onMouseLeave } = props;

  const title = translate!(COMPONENT_LABEL, TITLE_NAME, TITLE_NAME);
  const queryString = toQueryString({
    screen_name: props.accountToFollow,
    href: `${TWITTER_URL_PREFIX}/${props.accountToFollow}`,
    show_count: props.showCount,
    show_screen_name: props.showScreenName,
    lang: props.lang,
    align: 'left',
    compId: props.id,
    origin: props.currentUrl.origin,
    widgetType: WidgetType.FOLLOW,
  });

  return (
    <WTwitterContent
      {...{
        id,
        title,
        widgetType: WidgetType.FOLLOW,
        queryString,
        width: props.width,
        height: props.height,
        onMouseEnter,
        onMouseLeave,
      }}
    />
  );
};

export default WTwitterFollow;
